import { variants as baseVariants } from "../variants";
import { Rounded } from "../rounded";
import { Size } from "../sizes";
import YButton from "@lib/components/YButton/YButton.vue";

export const sizeClasses: { [K in Size]: string } = {
  lg: "btn-lg",
  md: "btn-md",
  sm: "btn-sm",
  xs: "btn-xs",
};
export const sizeClassesMd: { [K in Size]: string } = {
  lg: "md:btn-lg",
  md: "md:btn-md",
  sm: "md:btn-sm",
  xs: "md:btn-xs",
};
export const sizeClassesLg: { [K in Size]: string } = {
  lg: "lg:btn-lg",
  md: "lg:btn-md",
  sm: "lg:btn-sm",
  xs: "lg:btn-xs",
};
export const sizeClassesXl: { [K in Size]: string } = {
  lg: "xl:btn-lg",
  md: "xl:btn-md",
  sm: "xl:btn-sm",
  xs: "xl:btn-xs",
};

export const variants = [...baseVariants, "link"] as const;
export type Variant = (typeof variants)[number];

export const variantClasses: { [K in Variant]: string } = {
  neutral: "",
  primary: "btn-primary",
  secondary: "btn-secondary",
  accent: "btn-accent",
  ghost: "btn-ghost",
  info: "btn-info",
  success: "btn-success",
  warning: "btn-warning",
  error: "btn-error",
  link: "btn-link",
};

export const roundedClasses: { [K in Rounded]: string } = {
  normal: "",
  none: "!rounded-none",
  pill: "!rounded-full",
};

export const shapes = ["circle", "square"] as const;
export type Shape = (typeof shapes)[number];

export const shapeClasses: { [K in Shape]: string } = {
  circle: "btn-circle",
  square: "btn-square",
};

export type Props = InstanceType<typeof YButton>["$props"];
