<template>
  <div class="w-full absolute inset-0" :style="{ top, left, bottom, right }">
    <router-view v-slot="{ Component }">
      <transition
        :enter-active-class="`${
          route.meta.enterActiveClass ?? 'animate-fade'
        } animate-duration-transition animate-ease-in-out`"
        :leave-active-class="`${
          route.meta.leaveActiveClass ?? 'animate-fade'
        } animate-reverse animate-duration-transition animate-ease-in-out`"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <ActionsManager />
</template>

<script lang="ts">
export const installPromptEventSymbol: InjectionKey<Ref<BeforeInstallPromptEvent | undefined>> =
  Symbol("INSTALL-PROMPT-EVENT");

export const supportInstallSymbol: InjectionKey<Ref<boolean>> = Symbol("SUPPORT-INSTALL-SYMBol");
</script>

<script setup lang="ts">
import { useAppStore } from "./store/app.store";
import ActionsManager from "./actions/ActionsManager.vue";
import { configSymbol } from "@formkit/vue";
import { setI18nLocale } from "./i18n";
import { isFirefox, isSafari } from "./utils/userAgent";

const appStore = useAppStore();
const route = useRoute();

onMounted(async () => {
  setI18nLocale(appStore.locale);
});

const config = inject(configSymbol);

watchEffect(() => {
  if (config) {
    config.locale = appStore.locale;
  }
});

const { top, right, bottom, left } = useScreenSafeArea();

const installPromptEvent = ref<BeforeInstallPromptEvent>();

onBeforeMount(() => {
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    installPromptEvent.value = e as BeforeInstallPromptEvent;
  });
});

const supportInstall = computed(() => !!installPromptEvent.value || isSafari() || isFirefox());

provide(installPromptEventSymbol, installPromptEvent);
provide(supportInstallSymbol, supportInstall);
</script>
