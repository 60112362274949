import {
  LookupAgencyResponse,
  LookupResponse,
} from "@src/types/services/agency.service";
import { fetch } from "./fetch";

export default class AgencyService {
  public static async lookup(email: string) {
    const response = await fetch.get("agencies/lookup?", {
      searchParams: {
        "filter[email]": email,
      },
    });

    const data: LookupResponse = await response.clone().json();

    return data.data;
  }

  public static async lookupAgency(agency: string, includes = false) {
    const response = await fetch.get(
      `agencies/${agency}/lookup${
        includes ? "?includes[]=sub-agencies&&includes[]=business-units" : ""
      }`
    );
    const data: LookupAgencyResponse = await response.clone().json();
    return data.data;
  }

  public static async linkAgency(agency: string, json?: Record<string, number>) {
    return await fetch.post(`accounts/${agency}`, {
      json,
    });
  }
}
