import { createRouter, createWebHistory } from "vue-router";

/* eslint-disable import/no-unresolved */
import generatedRoutes from "virtual:generated-pages";
import { setupLayouts } from "virtual:generated-layouts";
import {
  GlobalMiddleware,
  MiddlewareDefinition,
  NavigationGuardContext,
  RouteMiddleware,
} from "@src/types/router";
import middlewares from "./middlewares";
/* eslint-enable import/no-unresolved */

const router = createRouter({
  history: createWebHistory(),
  routes: setupLayouts(generatedRoutes),
});

const globalMiddlewares: GlobalMiddleware[] = [
  {
    name: "auth",
    except: [
      "register",
      "login",
      "reset-password",
      "legacy-candidate",
      "validate-email",
      "index",
    ],
  },
  {
    name: "themeColor",
  },
];

const getMiddlewareDefinition = (middleware: string): MiddlewareDefinition => {
  const splitted = middleware.split(":");
  if (splitted.length > 1) {
    return {
      name: splitted[0],
      params: splitted[1].split(",").map((param) => param.trim()),
    };
  }
  return {
    name: splitted[0],
  };
};

const processMiddlewares = async (
  context: NavigationGuardContext,
  definitions: MiddlewareDefinition[]
) => {
  if (definitions.length > 0) {
    let middlewareResponse = undefined;
    let index = 0;
    let currentMiddleware: RouteMiddleware | undefined =
      middlewares[definitions[index].name];
    do {
      middlewareResponse = await currentMiddleware(
        context.to,
        context.from,
        ...(definitions[index].params ?? [])
      );
      index++;
      currentMiddleware = definitions[index]
        ? middlewares[definitions[index].name]
        : undefined;
    } while (!middlewareResponse && currentMiddleware);

    return middlewareResponse;
  }
};

router.beforeEach(async (to, from) => {
  const definitions: MiddlewareDefinition[] = [];

  globalMiddlewares.forEach((globalMiddleware) => {
    if (
      !globalMiddleware.except?.find(
        (name) => typeof to.name === "string" && to.name.startsWith(name)
      )
    ) {
      definitions.push(getMiddlewareDefinition(globalMiddleware.name));
    }
  });

  if (to.meta.middlewares) {
    const toMiddlewaresNames = Array.isArray(to.meta.middlewares)
      ? to.meta.middlewares
      : [to.meta.middlewares];
    toMiddlewaresNames.forEach((name) =>
      definitions.push(getMiddlewareDefinition(name))
    );
  }

  const response = await processMiddlewares({ to, from }, definitions);

  if (response) return response;
});

router.onError((error) => {
  if (/Failed to fetch dynamically imported module/i.test(error.message)) {
    window.location.reload();
  }
});

export { router };
