import { ModalAlertBus } from "@lib/plugins/ModalAlertPlugin";
import { i18n } from "@src/i18n";
import { BeforeErrorHook } from "ky";

let hasFatalErrorOpen = false;

const whitelist: Record<string, number[]> = {
  "/reset-password": [425],
  "/accounts/activation/reset": [425],
};

const fatalErrorHook: BeforeErrorHook = (error) => {
  const whitelistIndex = Object.keys(whitelist).find(
    (value) =>
      `${import.meta.env.VITE_SERVER_BASEURL}${value}` === error.request.url
  );

  if (
    whitelistIndex &&
    whitelist[whitelistIndex].includes(error.response.status)
  )
    return error;

  if (
    ![400, 401, 403, 404, 409, 422].includes(error.response.status) &&
    !hasFatalErrorOpen
  ) {
    hasFatalErrorOpen = true;
    ModalAlertBus.modalAlert({
      type: "error",
      title: i18n.global.t("fatal_error.title"),
      text: i18n.global.t("fatal_error.text"),
      onClose() {
        hasFatalErrorOpen = false;
      },
    });
  }
  return error;
};

export default fatalErrorHook;
