import { actionsBus } from "@src/actions/ActionsBus";
import { ActionResponse } from "@src/types/services/responses";
import { BeforeErrorHook } from "ky";

const actionsHook: BeforeErrorHook = async (error) => {
  const contentType = error.response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    const data: ActionResponse = await error.response.clone().json();
    if (data?.errors?.required_action?.code) {
      actionsBus.emit(data.errors.required_action);
    }
  }
  return error;
};

export default actionsHook;
