<template>
  <select
    :id="id"
    :name="name"
    :multiple="mode !== 'single'"
    class="absolute invisible h-0 m-0 max-h-0"
    :required="required"
    @focus.prevent="open = true"
  >
    <template v-for="option in optionsData.options.value" :key="option.label">
      <optgroup v-if="'options' in option" :label="option.label">
        <option
          v-for="sub in option.options"
          :key="sub.label"
          :value="sub.value"
          :selected="
            !!selectOptions.find((selected) => selected.value === sub.value)
          "
        >
          {{ sub.label }}
        </option>
      </optgroup>
      <option
        v-else
        :value="option.value"
        :selected="
          !!selectOptions.find((selected) => selected.value === option.value)
        "
      >
        {{ option.label }}
      </option>
    </template>
  </select>
  <a
    ref="reference"
    class="relative flex items-center select"
    :class="{ 'bg-none': !caret || optionsData.loading.value, 'pr-2': !caret }"
    href="#"
    v-bind="$attrs"
    @click.prevent="open = true"
    @focus.prevent="open = true"
    @keydown.prevent.tab="onInputTab"
  >
    <div
      class="flex items-center w-full gap-1"
      :class="{ 'flex-wrap py-2': mode !== 'single' }"
    >
      <slot name="prefix" :open="open"></slot>
      <DisplaySelect
        :mode="mode"
        :selected-options="selectOptions"
        :placeholder="placeholder"
        :filter="filter"
        :open="open"
        @delete="deleteOption"
      />
      <input
        v-if="filter && !appStore.isTouch"
        ref="input"
        type="text"
        class="flex-1 p-0 leading-none bg-transparent outline-none min-w-1/3 text-inherit"
        :class="{ hidden: !open }"
        :placeholder="searchPlaceholder ?? $t('search')"
        :value="q"
        autocomplete="off"
        @input="onQInput"
      />
    </div>
    <span class="absolute -translate-y-1/2 right-2.5 top-1/2">
      <YSpinner v-if="optionsData.loading.value"></YSpinner
    ></span>
  </a>
  <YModal
    v-if="appStore.isTouch"
    v-model:open="open"
    :close-button="false"
    :modal-class="`!p-0 !bg-base-200 w-full ${filter ? '!h-screen' : ''}`"
  >
    <div class="flex items-center justify-end w-full pt-3 pb-3 pl-6 pr-4">
      <input
        v-if="filter"
        ref="input"
        type="text"
        class="w-full leading-none shadow outline-none input input-sm bg-base-100 text-inherit shadow-base-300/50"
        :class="{ hidden: !open }"
        :placeholder="searchPlaceholder ?? $t('search')"
        :value="q"
        autocomplete="off"
        @input="onQInput"
      />
      <YButton
        class="ml-3 text-sm"
        variant="ghost"
        size="xs"
        @click.prevent="open = false"
      >
        {{ $t("ok") }}
      </YButton>
    </div>
    <div
      ref="scrolling"
      class="w-full h-full px-6 pt-2 pb-10 overflow-y-auto bg-base-200"
      :class="{ 'border-t border-base-300/20': overflowY > 0 }"
    >
      <PlanySelectList
        ref="selectList"
        v-model="vmodel"
        :q="q"
        :filter-manual="filterManual"
        :mode="mode"
        :options="flattedOptions"
        :selected-options="selectOptions"
        :results="results"
        :item-size="52"
        :searching="optionsData.loading.value"
        :no-result-text="noResultText"
        :first-results="optionsData.firstResults.value"
        class="!bg-base-100 rounded-xl shadow shadow-base-300/50 border border-base-300/30"
        @toggle="onToggle"
      >
        <template v-for="(_, key) in $slots" v-slot:[key]="slotData">
          <slot :name="key" v-bind="slotData" />
        </template>
      </PlanySelectList>
    </div>
  </YModal>
  <Teleport v-else to="body">
    <div
      ref="floating"
      class="z-[100] menu-compact rounded-lg overflow-hidden shadow shadow-base-300/50"
      :style="dropdownStyle"
    >
      <div ref="scrolling" class="h-full max-h-[inherit] overflow-y-auto">
        <PlanySelectList
          v-model="vmodel"
          :q="q"
          :filter-manual="filterManual"
          :mode="mode"
          :options="flattedOptions"
          :selected-options="selectOptions"
          :results="results"
          :searching="optionsData.loading.value"
          :no-result-text="noResultText"
          :first-results="optionsData.firstResults.value"
          @toggle="onToggle"
          @blur="onListBlur"
        >
          <template v-for="(_, key) in $slots" v-slot:[key]="slotData">
            <slot :name="key" v-bind="slotData" />
          </template>
        </PlanySelectList>
      </div>
    </div>
  </Teleport>
</template>
<script lang="ts">
export type PlanySelectMode = "single" | "tags";

export type PlanySelectOptions = string[] | Record<string, any>[];

export interface PlanySelectProps {
  id?: string;
  name?: string;
  required?: any;
  mode?: PlanySelectMode;
  options: any;
  resolveOnLoad?: boolean;
  labelKey?: string;
  valueKey?: string;
  addEmptyOption?: boolean;
  modelValue?: any;
  closeOnSelect?: boolean;
  placeholder?: string;
  group?: boolean;
  groupLabelKey?: string;
  filter?: boolean;
  filterManual?: boolean;
  filterDelay?: number;
  filterMin?: number;
  searchPlaceholder?: string;
  modalInputFocus?: boolean;
  object?: boolean;
  caret?: boolean;
  noResultText?: string;
}

export interface PlanySelectFlattedOption {
  label: string;
  value: any;
  title?: boolean | undefined;
}

export interface PlanySelectEmits {
  (e: "update:modelValue", value: any): void;
  (e: "blur"): void;
}

export type PlanySelectComputedOption = {
  label: any;
  value: any;
  isEmpty?: boolean;
};

export type PlanySelectComputedGroupOption = {
  label: any;
  options: PlanySelectComputedOption[];
};

export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { useAppStore } from "@src/store/app.store";
import PlanySelectList from "./PlanySelectList.vue";
import { useData } from "./useData";
import { useDropdown } from "./useDropdown";
import { useOptions } from "./useOptions";
import { useSearch } from "./useSearch";

const appStore = useAppStore();

const props = withDefaults(defineProps<PlanySelectProps>(), {
  mode: "single",
  labelKey: "label",
  valueKey: "value",
  resolveOnLoad: true,
  addEmptyOption: true,
  closeOnSelect: true,
  group: false,
  groupLabelKey: "label",
  filter: false,
  caret: true,
  modalInputFocus: false,
});
const emits = defineEmits<PlanySelectEmits>();

const q = ref("");
function onQInput(e: Event) {
  q.value = (e.target as HTMLInputElement).value;
}

const optionsData = useOptions(props, q);
const flattedOptions = computed((): PlanySelectFlattedOption[] => {
  const options: PlanySelectFlattedOption[] = [];

  optionsData.options.value.forEach((option) => {
    if ("options" in option) {
      options.push({
        value: Infinity,
        label: option.label,
        title: true,
      });

      option.options.forEach((subOption) => {
        if (!subOption.isEmpty) {
          options.push(subOption);
        }
      });
    } else if (!option.isEmpty) options.push(option);
  });

  return options;
});

const { vmodel, toggleOption, deleteOption, selectOptions } = useData(
  props,
  emits,
  flattedOptions
);

const searchQ = computed(() => (props.filterManual ? "" : q.value));
const results = useSearch(flattedOptions, searchQ);

const reference = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);
const scrolling = ref<HTMLElement | null>(null);
const input = ref<HTMLInputElement | null>(null);

const { open, style: dropdownStyle, update } = useDropdown(reference, floating);

const { y: overflowY } = useScroll(scrolling);

watch(results, () => {
  overflowY.value = 0;
});

watch(open, (open) => {
  update();
  if (open && (!appStore.isTouch || props.modalInputFocus)) {
    nextTick(() => {
      input.value?.focus();
    });
  } else {
    q.value = "";
    emits("blur");
  }
});

function onToggle(value: PlanySelectFlattedOption) {
  toggleOption(value);

  if (props.closeOnSelect) {
    open.value = false;
  }
}

function onInputTab() {
  floating.value?.querySelector("a")?.focus();
}

function onListBlur() {
  if (props.filter && input.value) input.value.focus();
  else if (floating.value) floating.value.querySelector("a")?.focus();
}
</script>
