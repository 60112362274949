import { login } from "./login";
import { registration } from "./registration";

export const account = {
  link_agency: async () => (await import("./link_agency")).default,
  ...Object.fromEntries(
    Object.entries(login).map(([key, value]) => [`login.${key}`, value])
  ),
  ...Object.fromEntries(
    Object.entries(registration).map(([key, value]) => [
      `registration.${key}`,
      value,
    ])
  ),
};
