<template>
  <YModal
    v-model:open="modalOpen"
    :backdrop="backdrop"
    :backdrop-close="backdropClose"
    :backdrop-class="backdropClass"
    :modal-class="modalClass"
    :close-button="closeButton"
  >
    <div class="space-y-2 text-center">
      <Component
        :is="(typeIcons[type].icon as any)"
        :class="typeIcons[type].class"
        class="inline-block text-6xl pb-2"
      />
      <h2 v-if="title">{{ title }}</h2>
      <h5 v-if="text" v-html="text" />
      <div
        v-if="buttons"
        class="flex justify-center items-end flex-wrap gap-2 pt-4"
      >
        <YButton
          v-for="button in buttons"
          :key="button.name"
          v-bind="(button.buttonProps as any)"
          :loading="!!buttonsLoading[button.name]"
          @click.prevent="button.onClick?.(exposed)"
        >
          {{ button.label }}
        </YButton>
      </div>
    </div>
  </YModal>
</template>

<script setup lang="ts">
import {
  Button,
  ModalExpose,
  Type,
  typeIcons,
} from "@lib/types/plugins/ModalAlertPlugin";
import YModal from "../YModal.vue";
import YButton from "../YButton/YButton.vue";

interface Props {
  type?: Type;
  title?: string;
  text?: string;
  backdrop?: boolean;
  backdropClass?: string;
  backdropClose?: boolean;
  modalClass?: string;
  closeButton?: boolean;
  buttons?: Button[];
  onClose?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  backdrop: true,
  backdropClose: true,
  closeButton: true,
  type: "default",
});

const modalOpen = ref(true);

watch(modalOpen, (open) => {
  if (!open && props.onClose) {
    props.onClose();
  }
});

const buttonsLoading = reactive<Record<string, boolean>>({});

const exposed: ModalExpose = {
  close: () => {
    modalOpen.value = false;
  },
  setButtonLoading: (name: string, loading = true) => {
    buttonsLoading[name] = loading;
  },
};

defineExpose(exposed);
</script>
