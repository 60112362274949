import { Breakpoint, breakpoints } from "@src/types/global";
import { RouteLocationNormalized } from "vue-router";
import { setOptions } from "@mobiscroll/javascript";
import { i18n, setI18nLocale } from "@src/i18n";
import { useGenericStore } from "./generic.store";
import { useLocalStorageRef } from "@src/composables/useLocalStorageRef";

function useRouteStore() {
  const router = useRouter();

  const fromRoute = ref<RouteLocationNormalized>();
  const toRoute = ref<RouteLocationNormalized>();

  function updateRoutes(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized
  ) {
    toRoute.value = to;
    fromRoute.value = from;
  }
  router.beforeEach(updateRoutes);

  return {
    fromRoute,
    toRoute,
    updateRoutes,
  };
}

function useDarkStore() {
  const { data: isDark } = useLocalStorageRef("is-dark", {
    defaultValue: window.matchMedia("(prefers-color-scheme: dark)").matches,
  });
  const baseThemeColors = {
    light: "rgb(255, 255, 255)",
    dark: "rgb(61, 68, 81)",
  };
  const themeColors = reactive({ ...baseThemeColors });
  const metaThemeColor: HTMLMetaElement | null = document.querySelector(
    "meta[name=theme-color]"
  );

  function applyDark() {
    if (isDark.value) {
      document.documentElement.dataset.theme = "ynalp-dark";
      document.body.classList.add("dark");
      setOptions({
        themeVariant: "dark",
      });
    } else {
      document.documentElement.dataset.theme = "ynalp";
      document.body.classList.remove("dark");
      setOptions({
        themeVariant: "light",
      });
    }

    applyThemeColor();
  }

  function applyThemeColor() {
    if (metaThemeColor)
      metaThemeColor.content = isDark.value
        ? themeColors.dark
        : themeColors.light;
  }

  function setThemeColors(light?: string, dark?: string) {
    if (light && dark) {
      themeColors.light = light;
      themeColors.dark = dark;
    } else {
      Object.assign(themeColors, baseThemeColors);
    }
  }

  watchEffect(applyDark);

  return {
    isDark,
    applyDark,
    applyThemeColor,
    setThemeColors,
  };
}

function useScreenStore() {
  const { width, height } = useWindowSize();

  window.visualViewport?.addEventListener("resize", () => {
    width.value = window.document.documentElement.clientWidth;
    height.value = window.document.documentElement.clientHeight;
  });

  const isTouch = useMediaQuery("(pointer: coarse)");
  const isStandalone = useMediaQuery("(display-mode: standalone)");

  const disableSwipe = ref(false);

  watchEffect(() => {
    const vh = height.value * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    if (isTouch.value) {
      setOptions({
        theme: "auto",
      });
    } else {
      setOptions({
        theme: "ios",
      });
    }
  });

  function isSupperiorOrEqualBreakpoint(breakpoint: Breakpoint): boolean {
    return width.value >= breakpoints[breakpoint];
  }

  function isInferiorBreakpoint(breakpoint: Breakpoint): boolean {
    return width.value < breakpoints[breakpoint];
  }

  return {
    width,
    height,
    isTouch,
    isStandalone,
    disableSwipe,
    isSupperiorOrEqualBreakpoint,
    isInferiorBreakpoint,
  };
}

function useLocaleStore() {
  const genericStore = useGenericStore();

  async function setLocale(locale: string) {
    await setI18nLocale(locale);
    genericStore.refetchAll();
  }

  const locale = computed({
    get() {
      return i18n.global.locale.value;
    },
    set(value: string) {
      setLocale(value);
    },
  });

  return locale;
}

export const useAppStore = defineStore("app", () => {
  const { fromRoute, toRoute, updateRoutes } = useRouteStore();

  const {
    isDark,
    applyDark,
    applyThemeColor,
    setThemeColors,
  } = useDarkStore();

  const {
    width,
    disableSwipe,
    isInferiorBreakpoint,
    isSupperiorOrEqualBreakpoint,
    isTouch,
    isStandalone,
  } = useScreenStore();

  const locale = useLocaleStore();

  const action = ref<string>();

  return {
    fromRoute,
    toRoute,
    updateRoutes,
    action,
    isDark,
    setThemeColors,
    applyThemeColor,
    applyDark,
    width,
    isSupperiorOrEqualBreakpoint,
    isInferiorBreakpoint,
    isTouch,
    isStandalone,
    disableSwipe,
    locale,
  };
});
