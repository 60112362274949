import "./hotjar";
import "./styles/main.css";
import "@lib/themes/mobiscroll.scss";

import { createApp } from "vue";
import App from "@src/App.vue";
import { router } from "./router";

import { i18n } from "./i18n";
import tippyPlugin from "@lib/types/plugins/TippyPlugin";

import formkitConfig from "@src/formkit";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { toastPlugin } from "@lib/plugins/ToastPlugin";
import { modalAlertPlugin } from "@lib/plugins/ModalAlertPlugin";

import { User } from "./types/services/auth.service";
import { HTTPError } from "ky";

export const app = createApp(App);
const pinia = createPinia();

const reloadRegex = [
  /^.*'text\/html' is not a valid JavaScript MIME type.*$/i,
  /^.*Importing a module script failed*$/i,
  /^.*Unable to preload CSS*$/i,
];

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  onError: async (e) => {
    if(import.meta.env.DEV) {
      return false;
    }

    if (
      e.originalError instanceof Error &&
      reloadRegex.find((regex) => regex.test(e.originalError.message))
    ) {
      window.location.reload();
      return false;
    }

    if (e.originalError instanceof HTTPError) {
      if(e.originalError.response.status === 401)
        return false;

      if (
        e.originalError.request.headers.get("content-type") ===
        "application/json"
      ) {
        e.addMetadata("request", {
          body: await e.originalError.request.json(),
        });
      }
      if (
        e.originalError.request.headers
          .get("content-type")
          ?.startsWith("multipart/form-data")
      ) {
        e.addMetadata("request", {
          formData: await e.originalError.request.formData(),
        });
      }
    }
  },
});

const bugsnagVue = Bugsnag.getPlugin("vue");
if (bugsnagVue) app.use(bugsnagVue);

app.use(i18n);
app.use(router);
app.use(pinia);
app.use(toastPlugin);
app.use(modalAlertPlugin);
app.use(tippyPlugin);
app.use(plugin, formkitConfig);
app.mount("#app");
