import { HTTPError } from "ky";
import { Component, Ref } from "vue";
/* import YFormBase from "../components/YForm/YFormBase/YFormBase.vue";
import { YFormGroup } from ".."; */

export class ErrorData<T = any> {
  public raw: Record<string, any>;
  public message?: string;
  public violations?: Record<string, string>;
  public origin?: T;

  constructor(raw: Record<string, any>, origin?: T) {
    this.raw = raw;
    this.origin = origin;
    this.parseMessage();
    this.parseViolations();
  }

  public static async fromHTTPError(error: any) {
    if (error instanceof HTTPError && error.response.status === 422) {
      const errorData = await error.response.clone().json();
      const errorObject = this.parseErrorObject(errorData);
      if (errorObject) {
        return new ErrorData(errorObject.errors, error);
      }
    }
    return false;
  }

  public static parseErrorObject(error: any) {
    if (
      typeof error === "object" &&
      !Array.isArray(error) &&
      typeof error.errors === "object" &&
      !Array.isArray(error.errors)
    ) {
      return error as { errors: Record<string, any> };
    }
  }

  private parseMessage() {
    if (this.raw.message && typeof this.raw.message === "string")
      this.message = this.raw.message;
  }

  private parseViolations() {
    if (
      this.raw.violations &&
      typeof this.raw.violations === "object" &&
      !Array.isArray(this.raw.violations)
    )
      this.violations = this.raw.violations as Record<string, string>;
  }
}

export type InitFunction<T> = (el: T) => unknown;
export interface Field {
  name: string | number;
  component: Component | string;
  groupProps?: Record<string, unknown>;
  inputProps?: Record<string, unknown>;
  value?: unknown;
  rules?: any;
  inputRef?: Ref<unknown>;
}
