<template>
  <input
    :id="context.id"
    ref="input"
    type="text"
    :name="context.node.name"
    :disabled="context.disabled"
    :class="
      context.multiple
        ? '-z-1 absolute inset-0 opacity-0'
        : context.classes.input
    "
    readonly
    v-bind="context.attrs"
    @blur="context.handlers.blur"
  />
  <tag-input
    v-if="context.multiple"
    :for="context.id"
    :class="context.classes.input"
    :values="valueText"
    @remove="removeItem"
  />
</template>

<script setup lang="ts">
import { useMobiscroll } from "@lib/composables/useMobiscroll";
import {
  MbscDatepickerOptions,
  Datepicker,
  datepicker,
} from "@mobiscroll/javascript";

interface Context {
  id: string;
  node: {
    input: (val: any) => any;
    name: string;
  };
  handlers: {
    blur: () => any;
  };
  disabled: boolean;
  _value: any;
  value: any;
  classes: any;
  attrs: Record<string, any>;
  options: any[];
  mobiscrollOptions?: MbscDatepickerOptions;
  multiple?: boolean;
  dateFormat?: string;
}

interface Props {
  context: Context;
}

const props = defineProps<Props>();

const input = ref<HTMLInputElement>();

const getInstance = useMobiscroll<
  MbscDatepickerOptions,
  Datepicker,
  typeof datepicker
>(
  input,
  datepicker,
  {
    touchUi: "auto",
    showOnFocus: true,
    dateFormat: props.context.dateFormat ?? "D MMMM YYYY",
    selectMultiple: props.context.multiple,
    onInit(args, instance: Datepicker) {
      instance.setTempVal(props.context._value);
    },
    onOpen(args, instance: Datepicker) {
      instance.setTempVal(props.context._value);
    },
    onChange({ value }) {
      props.context.node.input(value);
    },
  },
  props.context.mobiscrollOptions
);

function removeItem(key: number) {
  if (Array.isArray(props.context._value)) {
    const _value = props.context._value;
    _value.splice(key, 1);

    getInstance()?.setTempVal(_value);
  }
}

const valueText = computed(() => {
  if (Array.isArray(props.context._value)) {
    return Array.from(props.context._value).map((value) =>
      dayjs(value).format(props.context.dateFormat ?? "D MMMM YYYY")
    );
  }
});

watch(
  () => props.context._value,
  (value, oldValue) => {
    if (JSON.stringify(value) !== JSON.stringify(oldValue))
      getInstance()?.setTempVal(value);
  },
  { deep: true }
);
</script>
