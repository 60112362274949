import tippy, { Instance, Props } from "tippy.js";
import { Directive, Plugin } from "vue";

const tippyPlugin: Plugin = {
  install(app) {
    let instance: Instance | undefined = undefined;

    const directive: Directive<Element, Props> = {
      mounted(el, binding) {
        if (binding.value) {
          instance = tippy(el, binding.value);
        }
      },
      updated(el, binding) {
        if (binding.value) {
          if (!instance || el !== instance.reference) {
            instance?.destroy();
            instance = tippy(el, binding.value);
          } else {
            instance.setProps(binding.value);
          }
        }
      },
      beforeUnmount() {
        instance?.destroy();
      },
    };

    app.directive("tippy", directive);
  },
};

export default tippyPlugin;
