import { AllowedLocale } from "@src/types/i18n";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const locales: Record<AllowedLocale, () => Promise<any>> = {
  fr: () => import("dayjs/locale/fr"),
};

export async function setLocale(locale: keyof typeof locales) {
  await locales[locale]();
  dayjs.locale(locale);
}

export default dayjs;
