<template>
  <ul
    class="divide-y bg-base-100 divide-base-300/50 menu menu-vertical flex-nowrap"
  >
    <template v-if="results.length">
      <li
        v-for="({ item }, index) in results"
        :key="item.value"
        :class="{ 'menu-title bg-base-300/80': item.title }"
        class="flex flex-col justify-center h-full"
      >
        <slot v-if="item.title" name="item-title" :item="item">
          <span :class="itemTitleClass">{{ item.label }}</span>
        </slot>
        <a
          v-else
          :class="[
            itemTitleClass,
            isSelected(item.value) ? selectedItemClass : '',
          ]"
          class="flex items-center"
          href="#"
          @click.prevent="$emit('toggle', item)"
          @blur="onOptionBlur(index, 'results')"
        >
          <slot name="item" :item="item">
            <template v-if="mode === 'single'">
              <FaSolidCircleCheck
                v-if="isSelected(item.value)"
                class="text-primary-content dark:text-primary shrink-0"
              />
              <FaLightCircle
                v-else
                class="text-primary-content/20 dark:text-primary/40 shrink-0"
              />
            </template>
            <template v-else>
              <FaSolidSquareCheck
                v-if="isSelected(item.value)"
                class="text-primary-content dark:text-primary shrink-0"
              />
              <FaLightSquare
                v-else
                class="text-primary-content/20 dark:text-primary/40 shrink-0"
              />
            </template>
            {{ item.label }}
          </slot>
        </a>
      </li>
    </template>
    <template v-else-if="options.length && (!q.length || filterManual)">
      <li
        v-for="(item, index) of options"
        :key="item.value"
        :class="{ 'menu-title bg-base-300/80': item.title }"
        class="flex flex-col justify-center h-full"
      >
        <slot v-if="item.title" name="item-title" :item="item">
          <span :class="itemTitleClass">{{ item.label }}</span>
        </slot>
        <a
          v-else
          :class="[
            itemTitleClass,
            isSelected(item.value) ? selectedItemClass : '',
          ]"
          class="flex items-center"
          href="#"
          @click.prevent="emits('toggle', item)"
          @blur="onOptionBlur(index, 'options')"
        >
          <slot name="item" :item="item">
            <template v-if="mode === 'single'">
              <FaSolidCircleCheck
                v-if="isSelected(item.value)"
                class="text-primary-content dark:text-primary shrink-0"
              />
              <FaLightCircle
                v-else
                class="text-primary-content/20 dark:text-primary/40 shrink-0"
              />
            </template>
            <template v-else>
              <FaSolidSquareCheck
                v-if="isSelected(item.value)"
                class="text-primary-content dark:text-primary shrink-0"
              />
              <FaLightSquare
                v-else
                class="text-primary-content/20 dark:text-primary/40 shrink-0"
              />
            </template>
            {{ item.label }}
          </slot>
        </a>
      </li>
    </template>
    <li v-else-if="!searching && firstResults" class="py-2">
      <span>{{ noResultText ?? $t("no_results") }}</span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { PlanySelectFlattedOption, PlanySelectMode } from "./PlanySelect.vue";
import { StyleValue } from "vue";
import Fuse from "fuse.js";

interface Props {
  q: string;
  mode: PlanySelectMode;
  filterManual: boolean | undefined;
  options: PlanySelectFlattedOption[];
  selectedOptions: PlanySelectFlattedOption[];
  results: Fuse.FuseResult<PlanySelectFlattedOption>[];
  modelValue?: any;
  itemClass?: StyleValue;
  selectedItemClass?: StyleValue;
  itemTitleClass?: StyleValue;
  noResultText?: string;
  searching: boolean;
  firstResults: boolean;
}

interface Emits {
  (e: "toggle", value: any): void;
  (e: "blur"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

function isSelected(value: any) {
  return !!props.selectedOptions.find((option) => option.value === value);
}

function onOptionBlur(index: number, type: "options" | "results") {
  if (index === props[type].length - 1) {
    emits("blur");
  }
}
</script>
